import { Step, StepDescription, StepNumber, StepsSection } from "../reusable.styles"

import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import React from "react"
import styled from 'styled-components';
import ValuePropsSection from "../components/sections/ValuePropsSection"
import TwoCol_ImageContentSection from "../components/sections/TwoCol_ImageContentSection"
import GeneralHeader from "../components/global/GeneralHeader"
import CtaSection from "../components/sections/CtaSection"

const FeedRemoteEmployees = ({ data, location }) => {

  const valueProps = [
    {
      headline:"Boost employee morale",
      subheadline:"Motivate remote employees by maintaining work routines like company-provided lunch.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933619/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/G-RemoteEmployeeMeals/EXPORTS-Illustrations-RemoteMeals-Icon1-HouseRocket-Citrus.png",
    },
    {
      headline:"Offer remote flexibility",
      subheadline:"Provide food options to remote employees, so they can order whatever they’re craving.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652235439/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Remote%20Employees/EXPORT-RemoteEmployees-ValueProp2.png",
    },
    {
      headline:"Attract top talent",
      subheadline:"Recruit new hires by offering world-class meal perks whether they’re remote or in-office.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1625001198/Grubhub%20Corporate%20Website/2021/Illustrations/G-RemoteEmployeeMeals/EXPORTS-Illustrations-RemoteMeals-Icon3-Trophy.png",
    },
  ]

  return (
    <Layout
      location={location}
      title={"Food for Remote Employees | Grubhub Corporate"}
      description={"Feed your remote employees Let’s build your meal plan <!– Submit By clicking “Submit” or by using this site, you agree to Grubhub’s terms of use and privacy policy. –> Support your team while they work from home We’ll help you deliver food and happiness to your team, no matter where they’re working. Boost […]"}
      noindex={true}
      nofollow={true}
      utag_data={{
        pageGroup: "",
        subGroup: "",
        pageName: "",
        brand: "grubhub"
      }}
      >
      <StyledNavbarOnlyLogo>
        <NavbarContainerOnlyLogo>
          <LogoHeader src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1663686777/HERMES/Logos/GH_Corporate_stacked-sRGB-OrangeGray.png" alt="Grubhub/Seamless Corporate Accounts" placeholder="blurred" width={196}/>
        </NavbarContainerOnlyLogo>
      </StyledNavbarOnlyLogo>
      <GeneralHeader
        headline="Feed your remote employees"
        preheader=""
        subheadline="We'll help you deliver food and happiness to your team, no matter where they're working."
        textColor="var(--color-secondary)"
        background="#E7F4EE"
        formID={1814}
        backgroundColorForm='#FFF'
        formHeading="Let's build your meal plan"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796710/Grubhub%20Corporate%20Website/2021/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-RemoteEmployees.jpg"
      />
      <ValuePropsSection
        headline="Support your team while they work from home"
        valueProps={valueProps}
        layout="vertical"
      />
      <TwoCol_ImageContentSection //eslint-disable-line
        imageAlignment="right"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652235439/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Remote%20Employees/CircularImage-RemoteEmployees.png"
        headline="How it works"
        backgroundColor="rgba(231,244,238,0.4)"
      >
        <StepsSection>
          <Step>
            <RestyledStepNumber>Step 1</RestyledStepNumber>
            <StepDescription>Set a budget—recurring or one-time</StepDescription>
          </Step>
          <Step>
            <RestyledStepNumber>Step 2</RestyledStepNumber>
            <StepDescription>Tell your team about their meal credits! </StepDescription>
          </Step>
          <Step>
            <RestyledStepNumber>Step 3</RestyledStepNumber>
            <StepDescription>Employees order delivery directly to their homes</StepDescription>
          </Step>
        </StepsSection>
      </TwoCol_ImageContentSection>
      <QuoteSection
        quote='"Grubhub has helped me feed all 2,000 of my remote employees. I’m not sure how I survived before the Corporate Grubhub Account."'
        author="Nea H"
        position="Director of People at Asurion"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-Asurion.png"
        alt="Asurion logo"
        backgroundColor="white"
      />
      <CtaSection
          headline="See what we can do for you"
          to="#general-header"
          backgroundColor="rgba(231,244,238,0.4)"
      />
    </Layout>
  )
}

export default FeedRemoteEmployees

const RestyledStepNumber = styled(StepNumber)`
  color:#108F57;
`;

const StyledNavbarOnlyLogo = styled.header`
    -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    padding: 20px 0;
    z-index: 2000;
    position: relative;
`;

const NavbarContainerOnlyLogo = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
`;

const LogoHeader = styled.img`
    min-width: 200px;
    align-self: center;
`;
